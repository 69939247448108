import { useState, useCallback, useEffect, useRef } from 'react'
import { FiSearch } from 'react-icons/fi'

import { t } from 'i18next'
import { debounce } from 'lodash'

import Input from '~/components/Form/Input'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Table from '~/components/Table'
import { history, isReadOnly } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useSidePanel } from '~/hooks/useSidePanel'

import { useAdvertiserPublisherLinks } from '../../hooks/useAdvertiserPublisherLinks'
import EditAdvertiserLink from '../EditAdvertiserLink'
import { advertisersLinkTableSchema } from './schema'

// import { Container } from './styles';

const AdvertiserLinksTable: React.FC = () => {
  const [innerLoading, setInnerLoading] = useState(false)

  const params = useQueryParams()
  const page = Number(params.get('page')) || 1
  const quantity = Number(params.get('quantity')) || 50
  const advertiserId = params.get('rmid')
  const advertiser_name = params.get('advertiser_name')
  const seller_id = params.get('seller_id')

  /**
   * Get advertisers
   */

  const {
    data: { data, total, currentPage },
    isPending
  } = useAdvertiserPublisherLinks()

  const handlePagination = useCallback(
    (value: number) => {
      params.set('page', String(value))
      history.push({ search: params.toString() })
    },
    [params]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      params.set('quantity', String(value.value))
      params.set('page', '1')
      history.push({ search: params.toString() })
    },
    [params]
  )

  // Patch
  const { addSidePanel, removeSidePanel } = useSidePanel()

  const handleClosePanel = useCallback(() => {
    const newParams = new URLSearchParams(params.toString())
    newParams.delete('rmid')
    history.push({ search: newParams.toString() })
  }, [params])

  useEffect(() => {
    if (advertiserId && !isReadOnly()) {
      addSidePanel({
        title: t('rm:config.advertisers.panel.title'),
        type: 'content',
        content: <EditAdvertiserLink key={advertiserId} />,
        callbackRemove: handleClosePanel
      })
    }

    if (!advertiserId) {
      removeSidePanel()
    }
  }, [
    addSidePanel,
    advertiserId,
    handleClosePanel,
    page,
    params,
    quantity,
    removeSidePanel
  ])

  // Filter
  const executeDebounceFunc = (func: () => void) => func()

  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  const handleFilter = (data: OnChangeInputData) => {
    const { name, value } = data

    if (!value) {
      const newParams = new URLSearchParams(params.toString())
      newParams.delete(name)
      history.push({ search: newParams.toString() })
      setInnerLoading(false)

      return
    }

    params.set(name, String(value))
    params.set('page', '1')
    history.push({ search: params.toString() })

    setInnerLoading(false)
  }

  const handleChange = (data: OnChangeInputData) => {
    setInnerLoading(true)
    handleDebounce(() => handleFilter(data))
  }

  return (
    <section>
      <form className="d-flex gap-2 mb-2">
        <Input
          name="advertiser_name"
          onChange={handleChange}
          defaultValue={advertiser_name}
          icon={FiSearch}
          placeholder={t('rm:config.advertisers.filter.name')}
          type="search"
          loading={innerLoading}
        />

        <Input
          name="seller_id"
          onChange={handleChange}
          defaultValue={seller_id}
          icon={FiSearch}
          placeholder={t('rm:config.advertisers.filter.sellerId')}
          type="search"
          loading={innerLoading}
        />
      </form>

      <ListPagination
        total={total}
        label={t(
          `rm:config.advertisers.pagination.label.${total > 1 ? 'plural' : 'singular'}`
        )}
        currentPage={currentPage}
        itemsPerPage={quantity}
        menuPlacement="bottom"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
      />

      <Table
        smallerRowPadding
        schema={advertisersLinkTableSchema()}
        data={data?.advertisers}
        total={total}
      />

      <Loading status={isPending} />

      <ListPagination
        total={total}
        label={t(
          `rm:config.advertisers.pagination.label.${total > 1 ? 'plural' : 'singular'}`
        )}
        currentPage={page}
        itemsPerPage={quantity}
        menuPlacement="top"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </section>
  )
}

export default AdvertiserLinksTable
